const magentoDataSources = {
    mageCacheStorage: {
        token: {
            storageKey: 'mage-cache-storage',
        },
        permissionResolver: {
            value: 'permission_resolver',
            timestamp: 'permission_resolver.data_id',
            storageKey: 'mage-cache-storage',
        },
        cartResolver: {
            value: 'cart_resolver',
            timestamp: 'cart_resolver.data_id',
            storageKey: 'mage-cache-storage',
        },
        wishlistResolver: {
            value: 'wishlist_resolver',
            timestamp: 'wishlist_resolver.data_id',
            storageKey: 'mage-cache-storage',
        },
        customer: {
            value: 'customer',
            timestamp: 'customer.data_id',
            storageKey: 'mage-cache-storage',
        },
        cart: {
            value: 'cart',
            timestamp: 'cart.data_id',
            storageKey: 'mage-cache-storage',
        },
        rfqResolver: {
            value: 'rfq_resolver',
            timestamp: 'rfq_resolver.data_id',
            storageKey: 'mage-cache-storage',
        },
        debitorResolver: {
            value: 'debitor_resolver',
            timestamp: 'debitor_resolver.data_id',
            storageKey: 'mage-cache-storage',
        }
    },
}

const activeSource = magentoDataSources.mageCacheStorage;
export const config = {
    storageSource: activeSource,
}
