import React from 'react';
function RfqButton() {
    return (
        <a href="https://rio.reyher.de.local/de/rfq/quote/add/product/11938/qty/500/"
           data-href="https://rio.reyher.de.local/de/rfq/quote/add/product/11938/qty/qty-value-placeholder/"
           id="rfq-009121000030006" data-product-id="11938" data-name="rfq"
           className="button-reyher button-reyher--primary button-reyher--square rfq-ajax">
      <span className="button-reyher__link">
        <span className="reyhericon icon-anfragen"></span>
      </span>
        </a>
    );
};
export default RfqButton;