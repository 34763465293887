import React from "react";

function DropdownItem({name, action, permission, onItemClick}) {
    if (!permission) {
        return null;
    }

    return (
        <li className="dropdown-reyher__item" onClick={() => onItemClick(name)} data-action={action}>
            <a className="dropdown-reyher__link">{name}</a>
        </li>
    );
}

export default DropdownItem;