import SubMenu from './SubMenu';

/**
 * @param name
 * @param href
 * @param permission
 * @param subMenu
 * @returns {JSX.Element|null}
 * @constructor
 */
function NavigationItem({name, href, subMenu}) {

    if (subMenu && subMenu.length !== 0) {
        return (<li className="main-navigation__item">
            <a href={href} className="main-navigation__link">{name}</a>
            <SubMenu subItems={subMenu}/>
        </li>)
    }

    return (<li className="main-navigation__item">
        <a href={href} className="main-navigation__link">{name}</a>
    </li>)
}

export default NavigationItem;