import React from 'react';

function PrimaryButton() {
    return (
        <div className="search-form__input-container">
            <button type="submit" className="button-reyher button-reyher--primary button-reyher--primary" style={{width: "329px"}} title="">
                <span className="reyhericon icon-mutter" style={{"margin-right": "5px"}}></span>
                Suche starten
            </button>
        </div>
    );
}
export default PrimaryButton;