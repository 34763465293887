import React, {useEffect, useState} from "react";
import MenuItemMobile from "./MenuItemMobile";
import LocalStorage from "../../utils/localStorage";
import { __ } from '../../i18n';
import NavigationItem from "./NavigationItem";

/*
 * @todo implement event mapper from jQuery to vanilla JS
 * @todo check if specific local storage sate has changed
 * @returns {JSX.Element}
 * @constructor
 */
function MenuMobile() {
    const [permissionState, setPermissionState] = useState(false);
    const [wishlistState, setWishlistState] = useState(false);

    useEffect(() => {
        const handlePermissionReload = (event) => {
            setPermissionState(prevState => !prevState);
        };

        const handleWishlistReload = (event) => {
            setWishlistState(prevState => !prevState);
        };

        document.addEventListener("customer-data-reload-permission_resolver", handlePermissionReload);
        document.addEventListener("customer-data-reload-wishlist_resolver", handleWishlistReload);

        return () => {
            document.removeEventListener("customer-data-reload-permission_resolver", handlePermissionReload);
            document.removeEventListener("customer-data-reload-wishlist_resolver", handleWishlistReload);
        };
    }, []);

    const wishlists = LocalStorage.getWishlists() || [];

    const subMenu = wishlists.map((item, index) => ({
        name: parseInt(item.is_favorite) ? item.name : '',
        href: parseInt(item.is_favorite) ? '/multi/multiplewishlist/switchwishlist/mage_wishlist_id/' + item.id : ''
    })).filter(item => item.name !== '' && item.href !== '');

    return <nav className="">
        <ul className="collapse-menu">
            {LocalStorage.checkIfIsLoggedIn() ?
                LocalStorage.getRioScanPermission() ?
                    <MenuItemMobile
                        name={__('RIO|SCAN')}
                        href="/quickinput/transmission/index"
                        subMenu={[
                            {name: __('Scanner transmission'), href: '/quickinput/transmission/index'},
                            {name: __('Quickinput'), href: '/quickinput/view/index'},
                            {name: __('Instructions'), href: '/quickinput/view/instructions'}
                        ]}
                    /> :
                    <MenuItemMobile
                        name={__('Quickinput')}
                        href="/quickinput/view/index"
                    />
                : ""}
            {LocalStorage.checkIfIsLoggedIn() && LocalStorage.getFavoritePermission() ?
            <MenuItemMobile
                name={__('Favorites')}
                href="/favorite"
                subMenu={subMenu}
            /> : ""}
            {LocalStorage.checkIfIsLoggedIn() &&  (LocalStorage.getCartApprovalPermission() || LocalStorage.getCartApprovalRequestPermission() || LocalStorage.getCartDirectContinuePermission() )  ?
            <MenuItemMobile
                name={__('Permits')}
                href="/approval/listing/processed"
                subMenu={[
                    {name: __('sent baskets'), href: '/approval/listing/processed'},
                    LocalStorage.getCartApprovalPermission() || LocalStorage.getAdminPermission() ?
                    {name: __('received baskets'), href: '/approval/listing/received'} : ""
                ]}
            /> : ""}
            {LocalStorage.checkIfIsLoggedIn() && LocalStorage.getCartOfferPermission() ?
            <MenuItemMobile
                name={__('Offers Menu')}
                href="/rfq/offer/listing"
                subMenu={[
                    {name: __('Requests Submenu'), href: '/rfq/quote/listing'},
                    {name: __('Offers Submenu'), href: '/rfq/offer/listing'}
                ]}
            /> : ""}
            {LocalStorage.getFaqPermission() ?
            <MenuItemMobile
                name={__('FAQ')}
                href="/faq/entry/view"
            /> : ""}
            {!LocalStorage.checkIfIsLoggedIn() ?
            <MenuItemMobile
                name={__('Login')}
                href="/customer/account/login"
            /> : ""}
            {!LocalStorage.checkIfIsLoggedIn() ?
            <MenuItemMobile
                name={__('Register')}
                href={__('https://www.reyher.de/en/contact/request-access-data/')}
            /> : ""}
        </ul>
    </nav>;
}

export default MenuMobile;
