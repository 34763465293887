import { useRef, useState, useEffect } from 'react';
const OFFSET_DESKTOP = [105, 276];
const OFFSET_MOBILE = [-20, 18];
export function useFlyout() {
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);
    const flyoutRef = useRef(null);
    function handleCloseEvent() {
        setIsHovered(false);
    }
    useEffect(() => {
        window.addEventListener('close-flyout', handleCloseEvent);
        return () => {
            window.removeEventListener('close-flyout', handleCloseEvent);
        };
    }, []);
    function dispatchCloseFlyoutEvent() {
        const flyoutOpenElement = document.querySelector('.flyout-reyher--open');
        if (flyoutOpenElement && flyoutOpenElement !== flyoutRef.current) {
            const closeFlyout = new Event('close-flyout');
            window.dispatchEvent(closeFlyout);
        }
    }
    function handleMouseEnter() {
        dispatchCloseFlyoutEvent();
        setIsHovered(true);
        setPositionBasedOnButton();
    }
    function setPositionBasedOnButton() {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const offset = window.innerWidth > 1024 ? OFFSET_DESKTOP : OFFSET_MOBILE;
        setPosition({
            top: `${buttonRect.top - offset[0]}px`,
            left: `${buttonRect.left - offset[1]}px`,
        });
    }
    function handleClickOutside(event) {
        if (
            flyoutRef.current &&
            !flyoutRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)
        ) {
            setIsHovered(false);
        }
    }
    useEffect(() => {
        function handleScroll() {
            setIsHovered(false);
        }
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return { isHovered, position, buttonRef, flyoutRef, handleMouseEnter };
}