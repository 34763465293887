import React from 'react';
function CadButton() {
    const postData = {
        action: "https://rio.reyher.de.local/de/cadenas/order/preorder/",
        data: {
            products: "11938",
            form_key: "v3dIqjhOfLMsLxj8"
        }
    };
    const handleClick = () => {
        // Handle button click logic here
        // You can use the postData object for further processing if needed
    };
    return (
        <div className="productlist_list__button--cad">
            <button type="button" className="button-reyher button-reyher--primary button-reyher--square" title="CAD" onClick={handleClick}>
                <a href="javascript:void(0)" data-post={JSON.stringify(postData)}>
                    <span className="button-reyher__link">CAD</span>
                </a>
            </button>
        </div>
    );
};
export default CadButton;