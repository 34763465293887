import SubMenuItem from './SubMenuItem';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SubMenu(props) {
    return <div className="menu_rio">
        <div className="container-reyher">
            <div className="menu_rio__submenus">
                <ul className="menu_rio__menu-list">
                    <li className="menu_rio__menu-item">
                        <ul className="menu_rio__submenu-list">
                            {props.subItems.map((element) =>
                                <SubMenuItem name={element.name} href={element.href}/>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
}

export default SubMenu;