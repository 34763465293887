import React from 'react';

function SecondaryButton() {
    return (
        <button
            type="button"
            className="button-reyher button-reyher--secondary "
            style={{width: "329px"}}
            data-role=""
            data-href="#"
        >
      <span className="button-reyher__link">
        Zurücksetzen
      </span>
        </button>
    );
}
export default SecondaryButton;