import React from 'react';
import FlyoutComponent from "./FlyoutComponent";
import {useFlyout} from "./useFlyout";

function AddToWishlistButton() {
    const { isHovered, position, buttonRef, flyoutRef, handleMouseEnter } = useFlyout();
    const postData = {
        action: "https://rio.reyher.de.local/de/multi/multiplewishlist/AddToFavoriteMassAction/",
        data: {
            products: "[{\"product-id\":11938,\"qty\":500}]",
            wishlist_change_id: "152332"
        }
    };
    return (
        <div style={{ display: "inline-block" }}>
            <button
                type="button"
                className="jsAddToWishlist button-reyher button-reyher--primary button-reyher--square"
                data-product-id="11938"
                title="Zu Favoriten hinzufügen"
                aria-label="Zu Favoriten hinzufügen"
                data-action="add-to-wishlist"
                data-post={JSON.stringify(postData)}
                onMouseEnter={handleMouseEnter}
                ref={buttonRef}
            >
                <a href="#" className="add2wishlist-ajax action towishlist button-reyher__link">
                    <span className="reyhericon icon-favorite"></span>
                </a>
            </button>
            {isHovered && (
                <div ref={flyoutRef}>
                    <FlyoutComponent position={position} type={"wishlist"}/>
                </div>
            )}
        </div>
    );
};
export default AddToWishlistButton;