import React from 'react';
import FlyoutComponent from "./FlyoutComponent";
import { useFlyout } from './useFlyout';
function AddToCartButton() {
    const { isHovered, position, buttonRef, flyoutRef, handleMouseEnter } = useFlyout();
    return (
        <div style={{ display: "inline-block" }}>
            <form
                className="form-input-set__cart"
                data-product-sku="009121000030006"
                action="https://rio.reyher.de.local/de/checkout/cart/add/uenc/placeholder/product/11938/"
                method="post"
                onMouseEnter={handleMouseEnter}
            >
                <input type="hidden" name="product" value="11938" />
                <input type="hidden" name="uenc" value="dummy" />
                <input
                    type="hidden"
                    className="qty-sp-009121000030006"
                    name="qty"
                    value="500"
                />
                <input
                    name="form_key"
                    type="hidden"
                    value="v3dIqjhOfLMsLxj8"
                />
                <button
                    type="submit"
                    className="button-reyher button-reyher--primary button-reyher--square"
                    ref={buttonRef}
                >
                    <span className="button-reyher__link">
                        <span className="reyhericon icon-cart"></span>
                    </span>
                </button>
            </form>
            {isHovered && (
                <div ref={flyoutRef}>
                    <FlyoutComponent position={position} type={"cart"} />
                </div>
            )}
        </div>
    );
}
export default AddToCartButton;