import { __ } from '../../i18n';
import MetaNavigationItem from "./MetaNavigationItem";
import LocalStorage from "../../utils/localStorage";
import {useState} from "react";

function MetaNavigation() {
    const [state, setState] = useState(false);

    document.addEventListener("customer-data-reload-permission_resolver", (event) => {
        setState(prevState => !prevState);
    });

    return (
        <>
            { !LocalStorage.getUserName() ? (
                    <a href="/customer/account/login" className="link_ctext_reyher">{__('You are in guest access.')}</a>
                ) : (
                    <div>
                        <p className="user-info-rio__login-as">{__('Logged in as:')}
                            { LocalStorage.getUserName() ? (<span>&nbsp;{LocalStorage.getUserName()}&nbsp;</span>) : (<span> - </span>)}
                        </p>
                        <div className="meta-nav__dropdown">
                            <div className="dropdown dropdown--plain">
                                <a href="/customer/account/edit" className="dropdown__toggle">{__('My account')}</a>

                                <ul className="dropdown__list dropdown__content">

                                    <MetaNavigationItem
                                        name={__('My Account')}
                                        href="/customer/account/edit"
                                        permission={LocalStorage.checkIfIsLoggedIn() && LocalStorage.getAccountPermission()}
                                    />

                                    <MetaNavigationItem
                                        name={__('Orderhistory')}
                                        href="/sales/order/history"
                                        permission={LocalStorage.checkIfIsLoggedIn() && LocalStorage.getOrderHistoryPermission()}
                                    />

                                    <MetaNavigationItem
                                        name={__('Usermanagement')}
                                        href="/core/account/usermanagement"
                                        permission={LocalStorage.checkIfIsLoggedIn() && LocalStorage.getAdminPermission()}
                                    />

                                    <MetaNavigationItem
                                        name={__('label designer')}
                                        href="/etiketten-designer/designer/index"
                                        permission={LocalStorage.checkIfIsLoggedIn() && LocalStorage.getLabelDesignerPermission() && !LocalStorage.checkIfOciUser()}
                                    />

                                    <MetaNavigationItem
                                        name={__('self service')}
                                        href="/core/account/selfservice"
                                        permission={LocalStorage.checkIfIsLoggedIn() && LocalStorage.getFavoritePermission() && LocalStorage.getArticleCustomSkuEditPermission()}
                                    />

                                    <li className="dropdown__item">
                                        <a className="dropdown__link" href="/customer/account/logout">{__('Logout')}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
}

export default MetaNavigation;