import React, {useId, useState} from "react";
import FlyoutWithText from "../button/FlyoutWithText";

function SelectboxNoInput() {
    const [selectedValue1, setSelectedValue1] = useState('Von');
    const [selectedValue2, setSelectedValue2] = useState('Bis');
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const id1 = useId();
    const id2 = useId();
    function handleDropdownClick1() {
        setIsOpen1(!isOpen1);
        if (isOpen2) setIsOpen2(false);
    }
    function handleDropdownClick2() {
        setIsOpen2(!isOpen2);
        if (isOpen1) setIsOpen1(false);
    }
    function handleOptionClick1(value) {
        setSelectedValue1(value);
        setIsOpen1(false);
    }
    function handleOptionClick2(value) {
        setSelectedValue2(value);
        setIsOpen2(false);
    }

    return (<div className="search-form__data-list--2col">
        <div className="search-form__data-group" style={{width: "200px", height: "89px"}}>
            <div className="headings-reyher-container">
                <div className="headings-reyher__headline-h5">Durchmesser</div>
            </div>

            <input
                id="reyher_durchmesser-input"
                name="filter"
                data-filter-attribute="reyher_durchmesser"
                data-filter-multiselect="true"
                value=""
                type="hidden"
                className="search-form__input jsFilterInput"
            />
            {/*First Selectbox*/}
            <div className="search-form__input-container" style={{width: "87px"}}>
                <div
                    className={`js-DefaultSelectbox default-selectbox-reyher margin-bottom-15 selectbox-reyher--active ${isOpen1 ? 'default-selectbox-reyher-optionlist--active' : ''}`}
                    id={id1}
                    value="1.40"
                    data-placeholder="1.40"
                    role="tablist"
                    onClick={handleDropdownClick1}
                >
                    <div
                        className="default-selectbox-input-container"
                        style={{width: "83px", height: "40px"}}
                    >
                        <input
                            type="text"
                            className="form-input form-input-default"
                            placeholder="Von"
                            value={selectedValue1}
                            readOnly=""
                        />
                        <div className="popup-container">
                                <span
                                    className="default-selectbox-reyher-open-icon popup__icon reyhericon icon-arrow"></span>
                        </div>
                    </div>
                    <ul className="default-selectbox-reyher-optionlist" style={{width: "83px"}}>
                        <li className="default-selectbox-reyher-option">
                            <a
                                className="default-selectbox-reyher-selected"
                                href="javascript:void(0)"
                                data-selectbox-reyher-value=""
                                data-selectbox-reyher-label="1.40"
                                onClick={() => handleOptionClick1('Von')}
                            >
                                Von
                            </a>
                        </li>
                        <li className="default-selectbox-reyher-option">
                            <a
                                className="default-selectbox-reyher-selected"
                                href="javascript:void(0)"
                                data-selectbox-reyher-value=""
                                data-selectbox-reyher-label="1.40"
                                onClick={() => handleOptionClick1('1.40')}
                            >
                                1.40
                            </a>
                        </li>
                        <li className="default-selectbox-reyher-option">
                            <a
                                className="default-selectbox-reyher-selected"
                                href="javascript:void(0)"
                                data-selectbox-reyher-value=""
                                data-selectbox-reyher-label="1.40"
                                onClick={() => handleOptionClick1('1.60')}
                            >
                                1.60
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/*Second Selectbox*/}
            <div className="search-form__input-container">
                <div
                    className={`js-DefaultSelectbox default-selectbox-reyher margin-bottom-15 selectbox-reyher--active ${isOpen2 ? 'default-selectbox-reyher-optionlist--active' : ''}`}
                    value="1.40"
                    data-placeholder="1.40"
                    role="tablist"
                    id={id2}
                    onClick={handleDropdownClick2}
                >
                    <div
                        className="default-selectbox-input-container"
                        style={{width: "83px", height: "40px"}}
                    >
                        <input
                            type="text"
                            className="form-input form-input-default"
                            placeholder="Bis"
                            value={selectedValue2}
                            readOnly=""
                        />
                        <div className="popup-container">
                                <span
                                    className="default-selectbox-reyher-open-icon popup__icon reyhericon icon-arrow"></span>
                        </div>
                    </div>
                    <ul className="default-selectbox-reyher-optionlist" style={{width: "83px"}}>
                        <li className="default-selectbox-reyher-option">
                            <a
                                className="default-selectbox-reyher-selected"
                                href="javascript:void(0)"
                                data-selectbox-reyher-value=""
                                data-selectbox-reyher-label="1.40"
                                onClick={() => handleOptionClick2('Von')}
                            >
                                Bis
                            </a>
                        </li>
                        <li className="default-selectbox-reyher-option">
                            <a
                                className="default-selectbox-reyher-selected"
                                href="javascript:void(0)"
                                data-selectbox-reyher-value=""
                                data-selectbox-reyher-label="1.40"
                                onClick={() => handleOptionClick2('1.40')}
                            >
                                1.60
                            </a>
                        </li>
                        <li className="default-selectbox-reyher-option">
                            <a
                                className="default-selectbox-reyher-selected"
                                href="javascript:void(0)"
                                data-selectbox-reyher-value=""
                                data-selectbox-reyher-label="1.40"
                                onClick={() => handleOptionClick2('1.60')}
                            >
                                1.80
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <FlyoutWithText/>
    </div>);
}

export default SelectboxNoInput;