function MetaNavigationItem({name, href, permission}) {

    if (!permission) {
        return null;
    }

    return (
        <li className="dropdown__item">
            <a className="dropdown__link"
               href={href}>{name}</a>
        </li>
    );
}

export default MetaNavigationItem;