import React from 'react';
import AddToCartButton from "./AddToCartButton";
import RfqButton from "./RfqButton";
import CadButton from "./CadButton";
import AddToWishlistButton from "./AddToWishlistButton";
function SingleActionButton() {

    return (
        <td>
            <AddToCartButton />
            <span> </span>
            <RfqButton />
            <span> </span>
            <CadButton />
            <span> </span>
            <AddToWishlistButton />
        </td>
    );
}
export default SingleActionButton;