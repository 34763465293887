import React, {useState} from 'react';
import SelectBoxItem from './SelectBoxItem';

function SelectBox() {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    function toggleDropdown() {
        const iconLoader = document.getElementById("sg_selectbox_icon");
        if (!isOpen) {
            iconLoader.classList.remove('icon-loader__search-form');
            setTimeout(() => {
                iconLoader.classList.add('icon-loader__search-form');
            }, 300);
        }
        setIsOpen(!isOpen);
    }

    function handleItemClick(text) {
        setInputValue(text);
        setIsOpen(false);
    }

    function handleInputChange(e) {
        setInputValue(e.target.value);
    }

    return (<div id="material" className="search-form__input-container" data-name="reyher_material_text"
                 style={{height: "44px"}}>
            <label className="search-form__label">
                Werkstoff
            </label>
            <div className="selectbox-reyher selectbox-reyher--active" role="tablist">
                <a href="#" className="selectbox-reyher-label"></a>
                <li className={`form-input-reyher__search ${isOpen ? 'form-input-reyher__search--active' : ''}`}>
                    <input type="text" name="reyher_material_text" className="form-input form-input-search filter-input"
                           value={inputValue} onChange={handleInputChange}/>
                    <div
                        className={`popup-container ${isOpen ? 'popup-container--active' : 'popup-container--inactive'}`}
                        onClick={toggleDropdown}>
                        <span className="popup__icon reyhericon icon-arrow"></span>
                        <span id="sg_selectbox_icon" className="icon-loader icon-loader__search-form">
                            <span className="icon-loader__spinner-1"></span>
                            <span className="icon-loader__spinner-2"></span>
                            <span className="icon-loader__spinner-3"></span>
                            <span className="icon-loader__spinner-4"></span>
                            <span className="icon-loader__spinner-5"></span>
                        </span>
                    </div>
                </li>
                <ul style={{cursor: "pointer", "z-index": "9999"}}
                    className={`selectbox-reyher-optionlist ${isOpen ? 'selectbox-reyher-optionlist--active' : ''}`}>
                    <SelectBoxItem value="45421" text="58 - 5.8" onItemClick={handleItemClick}/>
                    <SelectBoxItem value="45411" text="70 - Messing" onItemClick={handleItemClick}/>
                    <SelectBoxItem value="45408" text="94 - Austenite (A4)" onItemClick={handleItemClick}/>
                    <SelectBoxItem value="45416" text="95 - Austenite (A5 / 1.4571)" onItemClick={handleItemClick}/>
                </ul>
            </div>
            <select id="reyher_material" name="reyher_material"
                    className="selectbox-reyher selectbox-reyher-hidden"
                    data-selectbox-reyher-search="" data-requiredinput="diniso">
            </select>
        </div>);
}

export default SelectBox;