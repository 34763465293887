import React, { useState, useEffect } from "react";
import NavigationItem from "./NavigationItem";
import LocalStorage from "../../utils/LocalStorage";
import { __ } from '../../i18n';

function NavigationBar() {
    const [permissionState, setPermissionState] = useState(false);
    const [wishlistState, setWishlistState] = useState(false);

    useEffect(() => {
        const handlePermissionReload = (event) => {
            setPermissionState(prevState => !prevState);
        };

        const handleWishlistReload = (event) => {
            setWishlistState(prevState => !prevState);
        };

        document.addEventListener("customer-data-reload-permission_resolver", handlePermissionReload);
        document.addEventListener("customer-data-reload-wishlist_resolver", handleWishlistReload);

        return () => {
            document.removeEventListener("customer-data-reload-permission_resolver", handlePermissionReload);
            document.removeEventListener("customer-data-reload-wishlist_resolver", handleWishlistReload);
        };
    }, []);

    const wishlists = LocalStorage.getWishlists() || [];

    const subMenu = wishlists.map((item, index) => ({
        name: parseInt(item.is_favorite) ? item.name : '',
        href: parseInt(item.is_favorite) ? '/multi/multiplewishlist/switchwishlist/mage_wishlist_id/' + item.id : ''
    })).filter(item => item.name !== '' && item.href !== '');

    return (
        <nav className="main-navigation main-navigation-navbar">
            <ul className="main-navigation__list">
                {LocalStorage.checkIfIsLoggedIn() ?
                    LocalStorage.getRioScanPermission() ?
                        <NavigationItem
                            name={__('RIO|SCAN')}
                            href="/quickinput/transmission/index"
                            subMenu={[
                                {name: __('Scanner transmission'), href: '/quickinput/transmission/index'},
                                {name: __('Quickinput'), href: '/quickinput/view/index'},
                            ]}
                        /> :
                        <NavigationItem
                            name={__('Quickinput')}
                            href="/quickinput/view/index"
                        />
                : ""}
                {LocalStorage.checkIfIsLoggedIn() && LocalStorage.getFavoritePermission() ?
                    <NavigationItem
                        name={__('Favorites')}
                        href="/favorite"
                        subMenu={subMenu}
                    /> : ""}
                {LocalStorage.checkIfIsLoggedIn() &&  (LocalStorage.getCartApprovalPermission() || LocalStorage.getCartApprovalRequestPermission() || LocalStorage.getCartDirectContinuePermission() )  ?
                    <NavigationItem
                        name={__('Permits')}
                        href="/approval/listing/processed"
                        subMenu={[
                            {name: __('sent baskets'), href: '/approval/listing/processed'},
                            LocalStorage.getCartApprovalPermission() || LocalStorage.getAdminPermission() ?
                                {name: __('received baskets'), href: '/approval/listing/received'} : ""
                        ]}
                    /> : ""}
                {LocalStorage.checkIfIsLoggedIn() && LocalStorage.getCartOfferPermission() ?
                    <NavigationItem
                        name={__('Offers Menu')}
                        href="/rfq/offer/listing"
                        subMenu={[
                            {name: __('Requests Submenu'), href: '/rfq/quote/listing'},
                            {name: __('Offers Submenu'), href: '/rfq/offer/listing'}
                        ]}
                    /> : ""}
                {LocalStorage.getFaqPermission() ?
                    <NavigationItem
                        name={__('FAQ')}
                        href="/faq/entry/view"
                    /> : ""}
                {!LocalStorage.checkIfIsLoggedIn() ?
                    <NavigationItem
                        name={__('Login')}
                        href="/customer/account/login"
                    /> : ""}
                {!LocalStorage.checkIfIsLoggedIn() ?
                    <NavigationItem
                        name={__('Register')}
                        href={__('https://www.reyher.de/en/contact/request-access-data/')}
                    /> : ""}
            </ul>
        </nav>
    );
}

export default NavigationBar;
