import SubMenuMobile from './SubMenuMobile';

/**
 * @param name
 * @param href
 * @param permission
 * @param subMenu
 * @returns {JSX.Element|null}
 * @constructor
 */
function MenuItemMobile({name, href, subMenu}) {
    if (subMenu && subMenu.length !== 0) {
        return (
            <li className="collapse-menu__item">
                <div className="collapse-menu__headline">
                    <a className="collapse-menu__link collapse-menu__item--has-submenu"
                       href={href}>
                        {name}
                    </a>
                    <a className="collapse-menu__toggle" href="#">
                        <span className="collapse-menu__toggle-icon teleicon icon-navigation-up"></span>
                        <span className="collapse-menu__toggle-icon teleicon icon-navigation-down"></span>
                    </a>
                </div>
                <SubMenuMobile subItems={subMenu}/>
            </li>
        )
    }

    return (
        <li className="collapse-menu__item">
            <div className="collapse-menu__headline">
                <a className="collapse-menu__link collapse-menu__item--has-submenu"
                   href={href}>
                    {name}
                </a>
            </div>
        </li>
    )
}

export default MenuItemMobile;