import SubMenuItemMobile from './SubMenuItemMobile';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SubMenuMobile(props) {
    return <div className="collapse-menu__content">
        <ul className="collapse-menu">
            <li className="collapse-menu__item">
                    {props.subItems.map((element) =>
                        <SubMenuItemMobile name={element.name} href={element.href}/>
                    )}
            </li>
        </ul>
    </div>
}

export default SubMenuMobile;