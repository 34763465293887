import React, { useState } from 'react';
import DropdownItem from "./DropdownItem";
import LocalStorage from "../../utils/localStorage";
function Dropdown(props) {
    const { initialState, dropdownItems } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(initialState);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleItemClick = (itemName) => {
        setSelectedItem(itemName);
        setIsOpen(false);
    };
    return (
        <div className={"flyout-reyher__content"}>
            <div className="d-flex justify-content-space-between">
                {/*TODO dynamically set dropdown-reyher--link-button on lower adjacent element */}
                <div className={`dropdown-reyher ${isOpen ? 'dropdown-reyher--open' : ''}`}>
                    <a className={`dropdown-reyher__toggle ${selectedItem !== initialState ? 'dropdown-reyher__toggle--active' : ''}`}
                       onClick={toggleDropdown} data-value="default">
                        {selectedItem}
                    </a>
                    <ul className="dropdown-reyher__list dropdown-reyher__content">
                        {dropdownItems.map((item, index) => (
                            <DropdownItem
                                key={index}
                                name={item.name}
                                href={item.href}
                                action={item.action}
                                permission={item.permission}
                                onItemClick={handleItemClick}
                            />
                        ))}
                    </ul>
                </div>
                <div className="margin-left-half">
                    <button id="" type="button" className="button-reyher button-reyher--primary button-reyher--square"
                            title="">
                    <span className="button-reyher__link">
                        <span className="reyhericon icon-arrow"></span>
                    </span>
                    </button>
                </div>
            </div>

        </div>
    );
}
export function createDropdownItem(name, href, action, permission)  {
    return {
        name,
        href,
        action,
        permission,
    };
};
export default Dropdown;