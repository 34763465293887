import React, {useState} from 'react';

function FlyoutWithText() {
    const [isHovered, setIsHovered] = useState(false);
    const [flyoutPosition, setFlyoutPosition] = useState({left: 0, top: 0});
    const handleMouseEnter = (event) => {
        setIsHovered(true);
        const buttonRect = event.target.getBoundingClientRect();
        const offset = window.innerWidth > 1024 ? [95, 11] : [-20, 18];
        setFlyoutPosition({
            top: `${buttonRect.bottom - offset[0]}px`,
            left: `${buttonRect.left - offset[1]}px`,

        });
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    function handleScroll() {
        setIsHovered(false);
    }

    window.addEventListener('scroll', handleScroll);
    return (
        <div
            type="button"
            className="circle-icons"
            data-target="flyout-3"
            data-dismiss="modal"
            data-hover="1"
            style={{position: 'relative'}}
        >
            <span
                aria-hidden="true"
                className="circle-icons__information"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
            </span>
            {isHovered && (
                <div
                    id="flyout-flyout-3"
                    className="flyout-reyher flyout-reyher--open"
                    style={{
                        position: 'fixed',
                        text: "no-wrap",
                        left: flyoutPosition.left,
                        top: flyoutPosition.top,
                    }}
                >
                    <div id="flyout-3" className="flyout-reyher__content">
                        <p className="paragraph-text paragraph-text--small">
                            Bitte geben Sie Ihre Suchparameter ein.
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FlyoutWithText;