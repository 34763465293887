/**
 * @param name
 * @param href
 * @returns {JSX.Element}
 * @constructor
 */
function SubMenuMobileItem({name, href}) {
    return <div className="collapse-menu__headline">
                <a className="collapse-menu__link" href={href}>
                    {name}
                </a>
    </div>
    }

export default SubMenuMobileItem;