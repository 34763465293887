import React, { useEffect, useState } from 'react';
import Dropdown, { createDropdownItem } from "../dropdown/Dropdown";
import LocalStorage from "../../utils/localStorage";
function FlyoutComponent(props) {
    const { position, type } = props;
    const dropdownItems = type === "wishlist" ? LocalStorage.getAllWishlists() : LocalStorage.getAllCarts();
    const initialState = () => {
        const arrProp = type === "wishlist" ? "is_favorite" : "is_active";
        return dropdownItems.find(item => item[arrProp] === '1').name;
    };
    const mappedDropdownItems = dropdownItems.map(cart => createDropdownItem(cart.name, "#", "", true));
    const [flyoutPosition, setFlyoutPosition] = useState(position);
    useEffect(() => {
        setFlyoutPosition(position);
    }, [position]);
    return (
        <div
            id="flyout-flyout-cart-multicart-productlist"
            className="flyout-reyher flyout-reyher--open flyout-reyher--right"
            style={{ top: flyoutPosition.top, left: flyoutPosition.left, position: "fixed", width:"329px"}}
        >
            <div id="flyout-cart-multicart-productlist" style={{ display: "block" }}>
                <div className="flyout-reyher__headline">
                    <h6>{type === "wishlist" ? "Favoritenliste" : "Warenkorb auswählen"}</h6>
                    <a href="#" className="flyout-reyher__close" data-dismiss="modal" aria-label="Close">
                        <span className="reyhericon icon-close"></span>
                    </a>
                </div>
                <Dropdown initialState={initialState} dropdownItems={mappedDropdownItems} />
            </div>
        </div>
    );
}
export default FlyoutComponent;