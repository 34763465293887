import React from 'react';

function SelectBoxItem({value, text, onItemClick}) {
    const handleClick = (event) => {
        onItemClick(text);
    };
    return (
        <li className="selectbox-reyher-option">
            <a className="" data-selectbox-reyher-value={value} onClick={handleClick}>
                {text}
            </a>
        </li>
    );
}

export default SelectBoxItem;